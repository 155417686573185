<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <section class="section p404">
          <div class="p404__content">
            <img class="p404__img" src="/public/images/p404.svg" alt="404" />
            <h2 class="p404__title">Страница не найдена</h2>
            <p class="p404__text">
              Возможно, вы ввели неправильный адрес, либо запрашиваемая Вами страница была перенесена или
              удалена.
            </p>
            <router-link :to="{ name: 'home' }" class="btn-blue_dark p404__link">На главную</router-link>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "NotFound",
  asyncData({ res }) {
    if (res) {
      res.status(404);
    }
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">


.p404 {
  display flex
  align-items center
  justify-content center

  &__content {
    display flex
    flex-direction column;
    align-items center
    justify-content center
    max-width 548px
    text-align center
  }

  &__img {
    width 360px
    height auto
    margin-bottom: 32px;
  }

  &__title {
    margin-top: 0;
  }

  &__text {
    margin-bottom: 32px;
  }

  &__link {
    height var(--main_height)
    padding 10px 20px
    font-size: 1em
    line-height 1.5
  }
}
</style>
